<template>
  <div>
    <pro-document-breadcrumbs :breadcrumbs="breadcrumbs" user-role="procurer" />
    <div class="row justify-end" style="padding: 10px 10px 0 0">
      <q-btn no-caps color="primary" @click="invite">
        <div class="ellipsis">
          {{ getRes("menu.InviteSuppliersToRegister") }}
        </div>
      </q-btn>
    </div>
    <ProReport
      title="CustMenu.Title.SearchSupplier"
      :columns="columns"
      v-model="fullList"
      row-key="id"
      auto-search
      :loading="loading"
    >
      <template #body-cell-button="{ row, col }">
        <q-td
          v-if="
            !row.passwordChange &&
            row.status === getRes('Document.Status.Created')
          "
          auto-width
          :class="col.__tdClass"
        >
          <pro-button
            label="System.Button.ResendEmail"
            icon="PROSmart-Send"
            @click="resendEmail(row)"
          />
        </q-td>
      </template>
    </ProReport>
  </div>
</template>

<script>
import ProDocumentBreadcrumbs from "@/components/PROSmart/ProDocumentBreadcrumbs";
import ProReport from "@/components/PROSmart/Report/ProReport";
import ProButton from "@/components/PROSmart/ProButton";

export default {
  components: {
    ProDocumentBreadcrumbs,
    ProReport,
    ProButton,
  },

  data() {
    return {
      loading: false,
      breadcrumbs: [
        {
          name: "menu.SupplierInvitation",
          icon: "PROSmart-Email",
        },
      ],
      statusMap: {
        0: "Created",
        1: "PendingForApproval",
        2: "Rejected",
        3: "Registered",
      },
      fullList: [],
    };
  },

  async created() {
    this.loading = true;
    const res = await this.$proSmart.supplierUi.getAllInvitationSuppliers(this);
    this.fullList = res.map((item) => {
      return {
        id: item.id,
        supplierCode: item.hcmUserId,
        processId: item.processId,
        supplierCompanyName:
          localStorage.getItem("Language") === "English"
            ? item.cmpyNameEng
            : item.cmpyNameChi,
        email: item.emailAddress,
        status: this.getRes(`Document.Status.${this.getStatus(item.status)}`),
        dateCreated: item.dateCreated,
        createdBy: item.createdBy,
        passwordChange: item.passwordChange,
      };
    });
    this.loading = false;
  },

  methods: {
    getStatus(status) {
      return this.statusMap[status];
    },
    invite() {
      this.$router.push({
        name: "InviteSuppliersRegister",
      });
    },
    resendEmail(row) {
      this.$proSmart.supplierUi
        .resendSystemMessageToInvitationSupplier(
          this,
          row.supplierCode,
          "P_InviteSupplier",
          row.processId
        )
        .then((res) => {
          res &&
            this.$alert(
              this.$t("WorkflowOperation.OperationSucceed"),
              this.$t("notification"),
              { confirmButtonText: this.getRes("ok") }
            );
        });
    },
  },

  computed: {
    columns() {
      return [
        {
          name: "supplierCode",
          label: this.getRes("Form.Section.SupplierCode"),
          field: "supplierCode",
          align: "left",
          sortable: true,
          style: "width: 200px",
          searchable: true,
          type: "string",
        },
        {
          name: "supplierCompanyName",
          label: this.getRes("Form.Field.SupplierCompanyName"),
          field: "supplierCompanyName",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
          style: "word-break: break-all;max-width: 400px;min-width:250px",
        },
        {
          name: "email",
          label: this.getRes("Form.Field.Email"),
          field: "email",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
          style: " word-break: break-all;max-width: 400px;min-width:250px",
        },
        {
          name: "status",
          label: this.getRes("Form.Field.Status"),
          field: "status",
          align: "left",
          searchable: true,
          sortable: true,
          type: "chip",
          list: [
            {
              label: this.getRes("Document.Status.Created"),
              value: this.getRes("Document.Status.Created"),
            },
            {
              label: this.getRes("Document.Status.PendingForApproval"),
              value: this.getRes("Document.Status.PendingForApproval"),
            },
            {
              label: this.getRes("Document.Status.Rejected"),
              value: this.getRes("Document.Status.Rejected"),
            },
            {
              label: this.getRes("Document.Status.Registered"),
              value: this.getRes("Document.Status.Registered"),
            },
          ],
        },
        {
          name: "dateCreated",
          label: this.getRes("CustMenu.Col.DateCreated"),
          field: "dateCreated",
          align: "left",
          searchable: true,
          sortable: true,
          type: "datetimeNull",
          convertTo: "datetimeNull",
        },
        {
          name: "createdBy",
          label: this.getRes("Form.Field.CreatedBy"),
          field: "createdBy",
          align: "left",
          searchable: true,
          sortable: true,
          type: "string",
        },
        {
          name: "button",
          label: this.getRes("System.Button.ResendEmail"),
          field: "",
          align: "left",
          searchable: false,
          sortable: true,
          type: "string",
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.red {
  color: red;
}
::v-deep button {
  translate: -16px;
}
</style>
